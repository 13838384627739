<template>
  <section id="medicos">
    <div class="row">
      <FiltrosAvancados />
      <div class="col-md-9">
        <div class="row">
          <div class="col-12 grid-margin stretch-card medicos">
            <div class="card card-statistics">
              <div class="row">
                <div class="card-col col-xl-3 col-lg-6 col-md-6 col-6 border-right">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                      <i class="mdi mdi-account-box-multiple text-info mr-0 mr-sm-4 icon-lg"></i>
                      <div class="wrapper text-center text-sm-left">
                        <p class="card-text mb-0">Médicos</p>
                        <div class="fluid-container">
                          <h3 class="mb-0 font-weight-medium">{{ totalRows | numberFilter }}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-col col-xl-3 col-lg-6 col-md-6 col-6 border-right">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                      <i class="mdi mdi mdi-phone-classic text-success mr-0 mr-sm-4 icon-lg"></i>
                      <div class="wrapper text-center text-sm-left">
                        <p class="card-text mb-0">Telefones</p>
                        <div class="fluid-container">
                          <h3 class="mb-0 font-weight-medium">{{ totalContatos.telefones | numberFilter }}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-col col-xl-2 col-lg-6 col-md-6 col-6 border-right">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                      <i class="mdi mdi mdi-cellphone-iphone text-muted mr-0 mr-sm-4 icon-lg"></i>
                      <div class="wrapper text-center text-sm-left">
                        <p class="card-text mb-0">Celulares</p>
                        <div class="fluid-container"> 
                          <h3 class="mb-0 font-weight-medium">{{ totalContatos.celulares | numberFilter }}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-col col-xl-2 col-lg-6 col-md-6 col-6 border-right">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                      <i class="mdi  mdi mdi-map-marker-radius text-primary mr-0 mr-sm-4 icon-lg"></i>
                      <div class="wrapper text-center text-sm-left">
                        <p class="card-text mb-0">Endereços</p>
                        <div class="fluid-container">
                          <h3 class="mb-0 font-weight-medium">{{ totalContatos.enderecos | numberFilter }}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-col col-xl-2 col-lg-6 col-md-6 col-6">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                      <i class="mdi mdi-mail-ru text-warning mr-0 mr-sm-4 icon-lg"></i>
                      <div class="wrapper text-center text-sm-left">
                        <p class="card-text mb-0">Emails</p>
                        <div class="fluid-container">
                          <h3 class="mb-0 font-weight-medium">{{ totalContatos.emails | numberFilter }}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 grid-margin stretch-card medicos">
            <b-card>
              <b-card-title>
                <b-row>
                  <b-col md="3">
                    <div class="d-flex align-items-center">
                      <h3 class="page-title mb-n2">Médicos</h3>
                      <p class="mt-2 mb-n1 ml-3 text-muted">
                        {{ totalRows | numberFilter }}
                      </p>
                    </div>
                  </b-col>
                  <b-col>
                    <b-button-toolbar aria-label="Barra de Ferramentas" class="d-flex flex-row-reverse">
                      <b-button-group class="mx-1">
                        <b-dropdown text="Exportar" variant="primary">
                          <template #button-content>
                            <b-spinner v-if="exporting" small type="grow"></b-spinner>
                            <b-icon v-else icon="download" aria-hidden="true"></b-icon>
                            Exportar
                          </template>
                          <b-dropdown-item @click="download('csv')">
                            <i class="fa fa-file-excel-o"></i> Comma-Separated
                            Values (.CSV)</b-dropdown-item>
                          <b-dropdown-item @click="download('xlsx')">
                          <i class="fa fa-file-excel-o"></i> Microsoft Excel
                          (.XLSX)</b-dropdown-item>
                          <b-dropdown-item @click="download('ods')"><i class="fa fa-file-excel-o"></i> Open Document
                            Format
                            (.ODS)
                          </b-dropdown-item>
                        </b-dropdown>
                        <b-button v-if="$store.getters['auth/hasPermission']('Importar Base')
                            " class="ml-1 border--solid" variant="primary" :to="{ name: 'MedicoImport' }">
                          <b-icon icon="upload" aria-hidden="true"></b-icon>
                          Importar Lote
                        </b-button>
                      </b-button-group>
                    </b-button-toolbar>
                  </b-col>
                </b-row>
              </b-card-title>

              <b-table ref="table" show-empty stacked="md" :items="search" :fields="fields" :current-page="currentPage"
                :per-page="perPage" :filter="medicoFiltros" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" responsive :busy="loadingTable">
                <template #table-busy>
                  <div class="text-center text-white my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Carregando...</strong>
                  </div>
                </template>

                <template #cell(nome)="row">
                  <span class="text-wrap">
                    {{ row.item.nome }}
                  </span>
                </template>

                <template #cell(medico_especialidades)="row">
                  <div>
                    <!-- Badge para o primeiro elemento -->
                    <b-badge 
                      v-if="row.item.medico_especialidades.length > 0" 
                      variant="outline-sucess"
                      >
                      {{ row.item.medico_especialidades[0].especialidade.nome }}
                    </b-badge>

                    <!-- Badge que mostra a contagem de elementos adicionais -->
                    <b-badge 
                      v-if="row.item.medico_especialidades.length > 1" 
                      :id="'tooltip-' + row.item.id"
                      variant="primary" pill
                      class="custom-badge"
                      v-b-tooltip.hover.right="'' + row.item.medico_especialidades.slice(1).map(e => e.especialidade.nome).join(' ')"
                      >
                      +{{ row.item.medico_especialidades.length - 1 }}
                    </b-badge>
                  </div>
                </template>

              </b-table>

              <b-row class="justify-content-md-center">
                <b-col md="5">
                  <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="md"
                    class="my-0"></b-pagination>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <b-sidebar id="sidebar-edit" no-title bg-variant="dark" text-variant="light" width="500px" shadow right backdrop
      backdrop-variant="dark">
      <div class="px-3 py-2">
        <h4 class="card-title">medico</h4>
        <!-- <p class="card-description">Basic form layout</p> -->
        <form class="forms-sample" @submit.stop.prevent="saveOrUpdate">
          <b-form-group label="Nome" label-for="nomeInput">
            <b-input-group>
              <b-form-input id="nomeInput" type="text" placeholder="Nome" v-model="$v.medico.nome.$model"
                :state="$v.medico.nome.$dirty ? !$v.medico.nome.$error : null" aria-describedby="nome-live-feedback"
                maxlength="255"></b-form-input>
              <b-form-invalid-feedback id="nome-live-feedback">
                <template v-if="!$v.medico.nome.required">Preenchimento obrigatório</template>
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <div class="d-flex">
            <b-button type="submit" variant="success" class="mr-2" :disabled="loading">
              <template v-if="loading">
                <b-spinner small type="grow"></b-spinner>
                Processando...
              </template>
              <template v-else> Enviar </template>
            </b-button>
          </div>
        </form>
      </div>
    </b-sidebar>
  </section>
</template>


<script>
import { mapState, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import MedicoService from "@/services/doktor/medico.service";
import FiltrosAvancados from "../../../components/doktor/FiltrosAvancados.vue";

export default {
  name: "medicos",
  components: {
    FiltrosAvancados,
  },
  data() {
    return {
      fields: [
        { key: "nome", sortable: true },
        { key: "crm", label: "CRM", sortable: true },
        { key: "uf", label: "UF", sortable: true },
        { key: "municipio.nome", label: "Município", sortable: false },
        { key: "medico_especialidades", label: "Especialidade", sortable: false },
        // { key: "actions", label: "Ações" },
      ],
      totalRows: 0,
      totalContatos:
      {
        "telefones": 0,
        "celulares": 0,
        "emails": 0,
        "enderecos": 0
      },
      currentPage: 1,
      perPage: 20,
      sortBy: "uf",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      loadingTable: false,
      medico: {
        id: null,
        nome: "",
      },
      loading: false,
      exporting: false,
    };
  },
  computed: {
    ...mapState(["medicoFiltros"]),
    ...mapGetters(["medicoFiltrosQuery"]),
  },
  mounted() {
    this.updateTotalContatos();
  },
  watch: {
    // Atualizar contadores somente se houver mudancas nos filtros
    medicoFiltrosQuery: function () {
      this.updateTotalContatos();
    },
  },
  methods: {
    search(ctx) {
      let params = {
        ...this.medicoFiltrosQuery,
        page: ctx.currentPage,
        perPage: ctx.perPage,
        orderBy: ctx.sortBy,
        sort: ctx.sortDesc ? "desc" : "asc",
      };

      this.loadingTable = true;

      const promise = MedicoService.getAll(params);

      return promise
        .then((response) => {
          this.loadingTable = false;
          const items = response.data.data;
          this.totalRows = response.data.total;
          return items || [];
        })
        .catch(() => {
          this.loadingTable = false;
          this.totalRows = 0;
        });
    },
    download(format) {
      let params = this.medicoFiltrosQuery;
      this.exporting = true;
      const promise = MedicoService.download(format, params);
      return promise
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `medicos_${Date.now()}.${format}`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => { })
        .then(() => {
          this.exporting = false;
        });
    },
    updateTotalContatos() {
      let params = {
        ...this.medicoFiltrosQuery,
      };
      // this.loadingTotal = true;
      MedicoService.totalContatos(params)
        .then((response) => {
          // this.loadingTotal = false;
          this.totalContatos = response.data;
        })
        .catch(() => {
          // this.loadingTotal = false;
          this.totalRows = null;
        });
    },
    saveOrUpdate() {
      this.loading = true;
      this.$v.medico.$touch();
      if (this.$v.medico.$anyError) {
        this.loading = false;
        return;
      }

      const promise = MedicoService.saveOrUpdate(this.medico);
      return promise
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: "Sucesso!",
            autoHideDelay: 1000,
            toaster: "b-toaster-bottom-left",
            variant: "success",
          });
          this.medico = {
            id: null,
            nome: "",
          };
          this.$v.medico.$reset();
          this.$refs.table.refresh();
          this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
        })
        .catch(() => { })
        .then(() => {
          this.loading = false;
        });
    },
    edit(item) {
      if (item) {
        this.medico = {
          id: item.id,
          nome: item.nome,
        };
      } else {
        this.medico = {
          id: null,
          nome: "",
        };
      }
      this.$v.medico.$reset();
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
    },
    remove(item) {
      this.$bvModal
        .msgBoxConfirm("Deseja realmente excluir este registro?", {
          size: "md",
          buttonSize: "md",
          okVariant: "primary",
          okTitle: "Sim",
          cancelVariant: "outline-secondary",
          cancelTitle: "Cancelar",
          footerClass: "p-2 border-top-0",
          centered: true,
          footerBgVariant: "dark",
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            const promise = MedicoService.delete(item.id);
            return promise
              .then((response) => {
                this.$bvToast.toast(response.data.message, {
                  title: "Sucesso!",
                  autoHideDelay: 1000,
                  toaster: "b-toaster-bottom-left",
                  variant: "success",
                });
                this.$refs.table.refresh();
              })
              .catch(() => { })
              .then(() => {
                this.loading = false;
              });
          }
        });
    },
  },
  validations: {
    medico: {
      nome: {
        required,
      },
    },
  },
};
</script>

<style scoped>
.custom-badge:hover {
  box-shadow: 0 0 10px blue;
  border: 1px solid blue;
  
}
.tooltip{
  max-width: 186px;
  
}
</style>